import React, {useState} from 'react'
import {useHistory} from 'react-router'
import {useSessionStorage} from 'usehooks-ts'
import Button from '../../../../components/Button'
import CheckoutButton from '../../../../components/CheckoutButton'
import Counter from '../../../../components/Counter'
import {createLineItem} from '../../../../services/shopify'
import {classList} from '../../../../services/util'
import './styles.scss'

export default function ProductOrder({
  className, productId, variantId, availableForSale, customKey, designOnly, selectedConfig, inventoryQuantity,
}) {
  const [quantity, setQuantity] = useState(1)
  const history = useHistory()
  const [customOrder, setCustomOrder] = useSessionStorage('custom', {})
  if (!availableForSale) return <div className={classList(['ProductOrder', className])}>
    <Button disabled>Sold out</Button>
  </div>
  const addToDesign = () => {
    setCustomOrder({
      ...customOrder,
      [customKey]: {
        productId: productId,
        variantId: variantId,
        config: selectedConfig,
      },
    })
    history.push('/design')
  }
  const designAction = customKey && customOrder[customKey] ? 'Update' : 'Add to'
  if (customKey && designOnly) return <div className={classList(['ProductOrder', className])}>
    <Button
      onClick={() => addToDesign()}>
      {designAction} Your Design
    </Button>
  </div>
  if (designOnly) return null
  return <div className={classList(['ProductOrder', className])}>
    <div className="ProductOrder__row">
      {inventoryQuantity === 1 ? null : <Counter
        className="ProductOrder__quantity"
        min={1}
        max={(inventoryQuantity || 0) > 1 ? inventoryQuantity : 99}
        onIncrement={() => setQuantity(quantity + 1)}
        onDecrement={() => setQuantity(quantity - 1)}>
        {quantity}
      </Counter>}
      <CheckoutButton
        className="ProductOrder__button"
        lineItems={[createLineItem(variantId, quantity, selectedConfig && selectedConfig.options)]}>
        Add to cart
      </CheckoutButton>
    </div>
    {!customKey ? null : <Button
      className="ProductOrder__secondaryButton"
      styleVariant="tertiary"
      onClick={() => addToDesign()}>
      {designAction} Your Design
    </Button>}
  </div>
}
