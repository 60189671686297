import React from 'react'
import './styles.scss'

export default function FormattedPrice({children, compareAt, ...props}) {
  const price = parseFloat(children) || 0
  const comparePrice = parseFloat(compareAt) || 0
  return <span {...props}>
    {price < comparePrice ? <span className="FormattedPrice__compare">{format(comparePrice)}</span> : null}
    <span>{format(price)}</span>
  </span>
}

export const format = (price) => {
  const roundPrice = Math.round(price)
  return roundPrice > 0 ? `$${roundPrice}` : 'FREE'
}
