import React, {useEffect, useMemo, useState} from 'react'
import moment from 'moment'
import {useContentful} from 'react-contentful'
import {useHistory, useParams} from 'react-router-dom'
import {useIsMounted} from 'react-tidy'
import Button from '../../../../../../components/Button'
import Dialog from '../../../../../../components/Dialog'
import Dropdown, {DropdownItem} from '../../../../../../components/Dropdown'
import HtmlTextEditor from '../../../../../../components/HtmlTextEditor'
import IconButton from '../../../../../../components/IconButton'
import Input from '../../../../../../components/Input'
import {useLoading} from '../../../../../../components/LoadingWrapper'
import ProductVendor from '../../../../../../components/ProductVendor'
import Text from '../../../../../../components/Text'
import {ContentModel, useProductTheorist} from '../../../../../../services/contentful'
import {callable, useRealtimeDoc} from '../../../../../../services/firebase'
import {isPending} from '../../../../../../services/product'
import ProductCategories from '../../components/ProductCategories'
import ProductFilters from '../../components/ProductFilters'
import ProductImageUpload from '../../components/ProductImageUpload'
import DashPriceInput from '../../../../components/DashPriceInput'
import ProductCustomizer from '../../components/ProductCustomizer'
import ProductStatus from '../../components/ProductStatus'
import {ReactComponent as FeesIcon} from '../../images/fees.svg'
import './styles.scss'

const initialState = {
  tags: ['brand-stx', 'category-head', 'condition-new', 'configurator-none', 'inventory-player'],
  title: '',
  description: '',
  status: '',
  images: [],
  price: 0,
  compareAtPrice: 0,
}
const statusOptions = ['ACTIVE', 'ARCHIVED', 'DRAFT']

export default function DashProductEdit({theorist}) {
  const isAdmin = theorist.role === 'admin'
  const [product, setProduct] = useState({})
  const [owner, setOwner] = useState(false)
  const [original, setOriginal] = useState(initialState)
  const [edits, setEdits] = useState(initialState)
  const [dialog, openDialog] = useState('')
  const [different, setDifferent] = useState(false)
  const [valid, setValid] = useState(false)
  const [saving, setSaving] = useState(false)
  const [fees, setFees] = useState(0)
  const history = useHistory()
  const {id} = useParams()
  const isMounted = useIsMounted()
  const {addLoad, removeLoad} = useLoading()
  const {loading, error, data} = useRealtimeDoc('products', id, id === 'new')
  const theoristVendor = useProductTheorist(product)
  const {
    loading: categoriesLoading,
    fetched: categoriesFetched,
    error: categoriesError,
    data: categoriesData
  } = useContentful({
    contentType: 'filter',
    query: {'fields.tag': 'category'},
  })
  const categories = !categoriesLoading && categoriesFetched && !categoriesError
    && categoriesData && categoriesData.items.length > 0 && ContentModel(categoriesData.items[0])
  useEffect(() => {
    if (!data) {
      setProduct({})
      return
    }
    const shopify = data.shopify || {}
    setOwner((shopify.tags || []).includes(theorist.handle))
    setProduct({
      ...data,
      ...shopify,
      statusProps: {pending: isPending(shopify.tags), status: shopify.status}
    })
  }, [data, theorist.handle])
  useEffect(() => {
    if (product) {
      const variant = !product.variants || !product.variants[0] ? {} : product.variants[0]
      const base = {
        tags: product.tags || initialState.tags,
        title: product.title || initialState.title,
        description: product.descriptionHtml || initialState.description,
        status: product.status || initialState.status,
        images: product.images || initialState.images,
        price: parseInt(variant.price) || initialState.price,
        compareAtPrice: parseInt(variant.compareAtPrice) || initialState.compareAtPrice,
      }
      setOriginal(base)
      setEdits(base)
    }
  }, [product])
  useEffect(() => {
    if (saving || loading) addLoad('dash')
    else removeLoad('dash')
    return () => removeLoad('dash')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saving, loading])
  useEffect(() => {
    setDifferent(JSON.stringify(edits) !== JSON.stringify(original))
    setValid(!!edits.title)
  }, [original, edits])
  useEffect(() => {
    setFees(!edits.price ? 0 : Math.floor(0.149 * edits.price + 10.29))
  }, [edits])
  const readAccess = useMemo(() => isAdmin || owner, [isAdmin, owner])
  const saveProduct = async () => {
    if (!different || !valid) return
    setSaving(true)
    try {
      let result
      if (id === 'new') {
        // Create product, then create images
        const {images, ...product} = edits
        result = await callable('createProductV2')(product)
        await Promise.all(images.map(image => {
          return callable('createProductImageV2')({id: result.data.id, src: image.url})
        }))
      } else {
        // Create images, then update product
        const images = await Promise.all(edits.images.map(image => !image.id
          ? callable('createProductImageV2')({id: id, src: image.url}).then(result => result.data)
          : Promise.resolve(image)))
        .then(result => result.map(image => {return {id: image.id}}))
        result = await callable('updateProductV2')({id: id, ...edits, images: images})
      }
      if (isMounted() && result.data.id) {
        setSaving(false)
        history.replace(`/dash/products/${result.data.id}`)
      }
    } catch (error) {
      console.log(error)
      if (isMounted()) setSaving(false)
    }
  }
  const toggleStatus = async () => {
    setSaving(true)
    try {
      await callable('updateProductV2')({id: id, status: product.status !== 'ARCHIVED' ? 'ARCHIVED' : 'DRAFT'})
    } catch (error) {
      console.log(error)
    } finally {
      if (isMounted()) setSaving(false)
    }
  }
  const deleteProduct = async () => {
    if (product.orderCount) return
    setSaving(true)
    try {
      await callable('deleteProductV2')({id: id})
    } catch (error) {
      console.log(error)
    } finally {
      if (isMounted()) {
        setSaving(false)
        history.replace('/dash/products')
      }
    }
  }
  const isNew = id === 'new'
  const closeDialog = () => openDialog('')
  const goBack = () => {
    if (different) openDialog('unsaved')
    else history.push('/dash/products')
  }
  if (!isNew && loading) return <div className="DashProductEdit" />
  if (error) console.log(error)
  if (!isNew && (!data || !readAccess)) return <div className="DashProductEdit">
    <div className="DashProductEdit__head">
      <IconButton icon="arrow-left" onClick={() => goBack()} />
      <Text
        className="DashProductEdit__title"
        styleVariant="heading3">There's no product at this address.</Text>
    </div>
    <Text>Check the URL and try again, or go back to your list of products to find it.</Text>
  </div>
  const isArchived = product.status === 'ARCHIVED'
  return <div className="DashProductEdit">
    <div className="DashProductEdit__head">
      <IconButton icon="arrow-left" onClick={() => goBack()} />
      <Text
        className="DashProductEdit__title"
        styleVariant="heading2">{isNew ? 'Add product' : product.title}</Text>
      {isNew ? null : <ProductStatus {...product.statusProps} />}
    </div>
    <div className="DashProductEdit__body">
      <div className="DashProductEdit__leftColumn">
        {!categories ? null : <ProductCategories
          categories={categories}
          tags={edits.tags}
          onChange={(value) => setEdits({...edits, tags: value})} />}
        <Input
          className="DashProductEdit__titleInput"
          label="Title"
          value={edits.title}
          onChange={(value) => setEdits({...edits, title: value})} />
        <Text styleVariant="heading3">Description</Text>
        <HtmlTextEditor
          initialValue={original.description}
          onChange={(value) => setEdits({...edits, description: value})} />
        <ProductImageUpload
          className="DashProductEdit__imageUpload"
          images={edits.images}
          onChange={(value) => setEdits({...edits, images: value})} />
        <ProductCustomizer
          className="DashProductEdit__productCustomizer"
          tags={edits.tags}
          onChange={(value) => setEdits({...edits, tags: value})} />
        <Text styleVariant="heading3">Pricing</Text>
        <div className="DashProductEdit__pricing">
          <DashPriceInput
            className="DashProductEdit__priceInput"
            label="Price"
            value={edits.price}
            onChange={(value) => setEdits({...edits, price: value})} />
          <DashPriceInput
            className="DashProductEdit__priceInput"
            label="Compare price"
            value={edits.compareAtPrice}
            onChange={(value) => setEdits({...edits, compareAtPrice: value})} />
        </div>
        {!fees ? null : <div className="DashProductEdit__fees">
          <Text>You're saving over <b>${fees}</b> in fees by listing on String Theory.</Text>
          <FeesIcon style={{
            minWidth: 32,
            marginLeft: 16,
          }} />
        </div>}
      </div>
      <div className="DashProductEdit__rightColumn">
        <div className="DashProductEdit__status">
          {isArchived ? <>
            <Text styleVariant="heading3">Status</Text>
            <Text className="DashProductEdit__statusMessage">
              Archived — hidden from your String Theory page and all sales channels.
            </Text>
            <Button
              className="DashProductEdit__statusButton"
              styleVariant="secondary"
              onClick={() => openDialog('status')}>
              Unarchive
            </Button>
          </> : <>
            <Dropdown
              label="Status"
              onSelected={(value) => setEdits({...edits, status: value})}>
              {statusOptions
                .filter(status => status !== 'ARCHIVED')
                .map((value, index) => <DropdownItem
                  key={value}
                  value={value}
                  selected={product.status === value}>
                  {value[0] + value.toLowerCase().substring(1)}
                </DropdownItem>)}
            </Dropdown>
            <Text
              className="DashProductEdit__statusMessage"
              styleVariant="body2">
              {product.status === edits.status ? '' : (
                edits.status === 'DRAFT'
                  ? 'This product will be hidden from your String Theory page and all sales channels.'
                  : 'This product will be available to your String Theory page and checked sales channels.'
              )}
            </Text>
          </>}
        </div>
        {isNew || !isAdmin ? null : <>
          <Text styleVariant="heading3">Vendor</Text>
          <ProductVendor
            className="DashProductEdit__vendor"
            theorist={theoristVendor}
            vendor={product.vendor} />
          <Text
            styleVariant="body2">
            Created {moment(product.createdAt).format('MMM D [at] h:mm a')}
          </Text>
          <Text
            className="DashProductEdit__vendor"
            styleVariant="body2">
            Updated {moment(product.updatedAt).format('MMM D [at] h:mm a')}
          </Text>
        </>}
        {!categories ? null : <ProductFilters
          categories={categories}
          tags={edits.tags}
          onChange={(value) => setEdits({...edits, tags: value})}
          isAdmin={!isNew && isAdmin} />}
      </div>
    </div>
    <div className="DashProductEdit__footer">
      {isNew ? null : <>
        <Button
          styleVariant="secondary"
          slim
          onClick={() => openDialog('status')}>
          {isArchived ? 'Unarchive' : 'Archive'}
        </Button>
        {!product.orderCount ? <Button
          styleVariant="warn"
          slim
          onClick={() => openDialog('delete')}>
          Delete
        </Button> : null}
      </>}
      <div className="DashProductEdit__footerSpace" />
      <Button
        slim
        disabled={!different || !valid}
        onClick={() => saveProduct()}>
        Save
      </Button>
    </div>
    <Dialog
      title={`${isArchived ? 'Unarchive' : 'Archive'} product`}
      open={dialog === 'status'}
      onDismiss={() => closeDialog()}>
      <Text>{isArchived ? 'Unarchiving this product will change its status to draft so you can work on it again.'
        : 'Archiving this product will hide it from your page and all String Theory sales channels.'}</Text>
      <div className="DashProductEdit__dialogActions">
        <Button slim styleVariant="tertiary" onClick={() => closeDialog()}>Cancel</Button>
        <Button slim onClick={() => toggleStatus()}>{isArchived ? 'Unarchive' : 'Archive'}</Button>
      </div>
    </Dialog>
    <Dialog
      title={`Delete ${product.title}?`}
      open={dialog === 'delete'}
      onDismiss={() => closeDialog()}>
      <Text>Are you sure you want to delete the product <b>{product.title}</b>? This can't be undone.</Text>
      <div className="DashProductEdit__dialogActions">
        <Button slim styleVariant="tertiary" onClick={() => closeDialog()}>Cancel</Button>
        <Button slim styleVariant="danger" onClick={() => deleteProduct()}>Delete</Button>
      </div>
    </Dialog>
    <Dialog
      title="Unsaved changes"
      open={dialog === 'unsaved'}
      onDismiss={() => closeDialog()}>
      <Text>If you leave this page, any unsaved changes will be lost.</Text>
      <div className="DashProductEdit__dialogActions">
        <Button slim styleVariant="tertiary" onClick={() => closeDialog()}>Cancel</Button>
        <Button
          className="DashProductEdit__deleteButton"
          styleVariant="danger"
          slim onClick={() => history.push('/dash/products')}>Leave page</Button>
      </div>
    </Dialog>
  </div>
}
