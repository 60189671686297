import React from 'react'
import {Link} from 'react-router-dom'
import Text from '../../../../components/Text'
import {isBlackFriday, isFulfilled, isOnDemand, isReturnEligible} from '../../../../services/product'
import {classList, joinWords} from '../../../../services/util'
import './styles.scss'

export default function ProductFooter({className, theorist, vendor, tags}) {
  return <div className={classList(['ProductFooter', className])}>
    <Text>
      Sold by {!theorist ? vendor : <Link
      className="ProductFooter__link" to={`/${theorist.theoristHandle}`}>{theorist.name}</Link>}. {joinWords([
        isFulfilled(tags) || isOnDemand(tags) || !theorist ? null : `Ships from ${theorist.locationText}.`,
        !isFulfilled(tags) ? null : 'Fulfilled by String Theory.',
        !isOnDemand(tags) ? null : 'This product is custom-made for you upon order and delivered in 3 weeks or less.',
      ])}
    </Text>
    {!isBlackFriday(tags) ? null : <Text className="ProductFooter__return">
      Black Friday through Cyber Monday only, take an additional $20 off this head using discount code <b>
      BF20</b> at checkout. Sale ends at midnight on November 29, 2022.
    </Text>}
    {!isReturnEligible(tags) ? null : <Text className="ProductFooter__return">
      This item can be returned in its original condition for any reason, free of charge, within 30 days. <Link
      className="ProductFooter__link" to="/terms/returns">Return policy</Link>
    </Text>}
  </div>
}
