import React from 'react'
import {Link} from 'react-router-dom'
import AppIcon from '../../../../../../components/AppIcon'
import AppImage from '../../../../../../components/AppImage'
import FormattedPrice from '../../../../../../components/FormattedPrice'
import Text from '../../../../../../components/Text'
import {classList} from '../../../../../../services/util'
import './styles.scss'

export default function LineItemGroup({className, lineItems, quantity}) {
  const notes = lineItems
    .map(item => (item.customAttributes.find(attr => attr.key === 'Notes') || {}).value)
    .filter(it => !!it)[0]
  const isDesign = lineItems.length > 1
    && lineItems[0].customAttributes.findIndex(attr => attr.key === 'Design ID') >= 0
  return <div className={classList(['LineItemGroup', className])}>
    {lineItems.map(lineItem => {
      const customAttributes = lineItem.customAttributes
        .filter(attr => attr.key !== 'Design ID' && attr.key !== 'Notes')
        .sort((a, b) => a.key.localeCompare(b.key))
      const originalUnitPrice = parseFloat(lineItem.originalUnitPriceSet.presentmentMoney.amount)
      const discountedUnitPrice = parseFloat(lineItem.discountedUnitPriceSet.presentmentMoney.amount)
      return <div
        key={(lineItem.variant || {}).id || lineItem.title}
        className="LineItemGroup__lineItem">
        <AppImage
          src={(lineItem.image || {}).url}
          width={48}
          height={48}
          radius={8}
          bordered/>
        <div className="LineItemGroup__lineItemBody">
          <div className="LineItemGroup__lineItemBodyTop">
            <div className="LineItemGroup__lineItemTitle">
              <Text styleVariant="label7">{lineItem.vendor}</Text>
              {!lineItem.product ? <Text className="LineItemGroup__lineItemLink">
                {lineItem.title}
              </Text> : <Link
                className="LineItemGroup__lineItemLink"
                to={`/products/${lineItem.product.handle}?v=${lineItem.variant.id.split('/').pop()}`}>
                {lineItem.title}
              </Link>}
            </div>
            <Text className="LineItemGroup__lineItemUnitPrice" styleVariant="body3">
              <FormattedPrice compareAt={originalUnitPrice}>{discountedUnitPrice}</FormattedPrice>
              <span>&nbsp;x {quantity}</span>
            </Text>
          </div>
          {!lineItem.variantTitle ? null : <Text
            className="LineItemGroup__lineItemInfo"
            styleVariant="body3">
            {lineItem.variantTitle}
          </Text>}
          {!customAttributes.length ? null : customAttributes.map(attr => {
            return <Text
              key={attr.key}
              className="LineItemGroup__lineItemInfo"
              styleVariant="body2">
              {attr.key}: {attr.value}
            </Text>
          })}
          {lineItem.review && <>
            <div className="LineItemGroup__reviewRating">
              {[1, 2, 3, 4, 5].map(ratingValue => <AppIcon
                key={ratingValue}
                className={classList([
                  'LineItemGroup__reviewRatingStar',
                  ratingValue <= parseInt(lineItem.review.rating) && 'LineItemGroup__reviewRatingStar--active',
                ])}
                icon="star" />)}
            </div>
            {lineItem.review.body && <Text
              className="LineItemGroup__lineItemInfo"
              styleVariant="body2">
              "{lineItem.review.body}"
            </Text>}
          </>}
        </div>
        <Text className="LineItemGroup__lineItemTotalPrice" styleVariant="body3">
          <FormattedPrice compareAt={quantity * originalUnitPrice}>
            {quantity * discountedUnitPrice}
          </FormattedPrice>
        </Text>
      </div>
    })}
    {!notes ? null : <Text className="LineItemGroup__lineItemNotes" styleVariant="body3">{notes}</Text>}
    {!isDesign ? null : <Text className="LineItemGroup__lineItemNotes" styleVariant="body2">
      Our standard pocket and shooter placement is a mid pocket with 2 shooters across,
      unless the customer has requested something else in the notes associated with this order.
    </Text>}
  </div>
}
