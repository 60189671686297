import React from 'react'
import {Link} from 'react-router-dom'
import {classList} from '../../services/util'
import AppIcon from '../AppIcon'
import AppImage from '../AppImage'
import Text from '../Text'
import './styles.scss'

export default function ProductVendor({className, theorist, vendor}) {
  const styles = classList(['ProductVendor', className])
  return !theorist
    ? <Text className={styles} styleVariant="heading5">{vendor}</Text>
    : <Link className={styles} to={`/${theorist.theoristHandle}`}>
      <div className="ProductVendor__image">
        <AppImage src={theorist.profilePicture.file.url} size={32} circular bordered />
        {!!theorist.metrics?.overall?.elite && <AppIcon className="ProductVendor__eliteIcon" icon="award-filled" />}
      </div>
      <Text className="ProductVendor__name" styleVariant="heading4">{theorist.name}</Text>
    </Link>
}
