// noinspection GraphQLUnresolvedReference

import React, {createContext, useContext, useEffect, useState} from 'react'
import {ApolloClient, ApolloProvider, createHttpLink, gql, InMemoryCache, useQuery} from '@apollo/client'
import {setContext} from '@apollo/client/link/context'
import {relayStylePagination} from '@apollo/client/utilities'

const httpLink = createHttpLink({
  uri: 'https://shop.stringtheory.us/api/2024-04/graphql.json',
})

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      'X-Shopify-Storefront-Access-Token': '0a807bbc4b18aa4d6c4288b54eca178e',
    }
  }
})

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          products: relayStylePagination(['query', 'sortBy', 'reverse']),
        },
      },
    },
  }),
})

export default function ShopifyProvider({children}) {
  return <ApolloProvider client={client}>
    <CheckoutProvider>{children}</CheckoutProvider>
  </ApolloProvider>
}

const CheckoutContext = createContext({
  checkout: null,
  loading: false,
  open: false,
  toggleCheckout: () => {},
  updateCheckout: () => {},
})

export const useCheckout = () => useContext(CheckoutContext)

export const CheckoutConsumer = CheckoutContext.Consumer

function CheckoutProvider({children}) {
  const [checkoutId, setCheckoutId] = useState(sessionStorage.getItem('checkout'))
  const [checkoutOpen, setCheckoutOpen] = useState(false)
  useEffect(() => sessionStorage.setItem('checkout', checkoutId), [checkoutId])
  const {loading, error, data} = useQuery(FETCH_CHECKOUT, {
    variables: {checkoutId: checkoutId},
    skip: !checkoutId || checkoutId === 'null',
  })
  useEffect(() => {
    if (data && data.node.completedAt) setCheckoutId(null)
  }, [data])
  if (error) console.log(`Failed to fetch cart: ${error}`)
  // noinspection JSValidateTypes
  return <CheckoutContext.Provider value={{
    checkout: !data || data.node.completedAt ? null : flattenData(data.node),
    loading: loading,
    open: checkoutOpen,
    toggleCheckout: (value) => setCheckoutOpen(value),
    updateCheckout: (value) => setCheckoutId(!value ? null : value.id),
  }}>{children}</CheckoutContext.Provider>
}

export function createLineItem(variant, quantity, extras) {
  return {
    customAttributes: Object.keys(extras || {}).map(k => {
      return {key: k, value: extras[k]}
    }),
    quantity: quantity || 1,
    variantId: Buffer.from(variant).toString('base64'),
  }
}

export function flattenData(data) {
  return Object.keys(data).map(key => {
    if (!data[key]) return {[key]: null}
    if (data[key]['edges']) return {[key]: data[key]['edges'].map(edge => flattenData(edge.node))}
    return {[key]: data[key]}
  }).reduce((prev, curr) => {
    return {...prev, ...curr}
  }, {})
}

export function resizeImage(url, desiredSize) {
  const reversedSrc = (url || '').split('').reverse().join('')
  const reversedSize = `_${desiredSize}x`.split('').reverse().join('')
  const replaced = reversedSrc.replace(/x\d+_/i, reversedSize)
  return replaced.split('').reverse().join('')
}

export function groupLineItems(lineItems) {
  const lineItemMap = (lineItems || [])
    .map(item => {
      return {
        key: (item.customAttributes.find(attr => attr.key === 'Design ID') || {value: 'single'}).value,
        value: item,
      }
    })
    .reduce((acc, curr) => {
      return {
        ...acc,
        [curr.key]: (acc[curr.key] || []).concat([curr.value])
      }
    }, {})
  return Object.keys(lineItemMap).map(k => {
    if (k === 'single') return lineItemMap[k].map(item => [item])
    return [lineItemMap[k]]
  }).reduce((acc, curr) => {
    return acc.concat(curr)
  }, [])
}

const ProductFragment = gql`
  fragment ProductFragment on Product {
    availableForSale
    compareAtPriceRange {
      minVariantPrice {
        amount
      }
    }
    descriptionHtml
    handle
    id
    images(first: 10) {
      edges {
        node {
          url(transform: {
            maxWidth: 480,
            preferredContentType: JPG
          })
        }
      }
    }
    metafields(identifiers: [
      {key: "orderCount", namespace: "firebase"},
      {key: "reviewCount", namespace: "firebase"},
      {key: "reviewTotal", namespace: "firebase"},
    ]) {
      id
      key
      value
    }
    priceRange {
      minVariantPrice {
        amount
      }
      maxVariantPrice {
        amount
      }
    }
    tags
    title
    variants(first: 100) {
      edges {
        node {
          id
          compareAtPrice {
            amount
          }
          image {
            url(transform:{
              maxWidth: 480,
              preferredContentType: JPG
            })
          }
          price {
            amount
          }
          sku
          title
        }
      }
    }
    vendor
  }
`

export const FETCH_MATERIALS = gql`
  query {
    products(query: "tag:material", first: 100) {
      edges {
        node {
          ...ProductFragment
        }
      }
    }
  }
  ${ProductFragment}
`

export const FETCH_THEORISTS = gql`
  query {
    products(query: "tag:theorist", first: 100) {
      edges {
        node {
          ...ProductFragment
        }
      }
    }
  }
  ${ProductFragment}
`

export const FETCH_MODULE = gql`
  query FetchModule($query: String!, $sortBy: ProductSortKeys!, $reverse: Boolean!, $first: Int = 100, $after: String) {
    products(query: $query, sortKey: $sortBy, reverse: $reverse, first: $first, after: $after) {
      edges {
        node {
          ...ProductFragment
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
  ${ProductFragment}
`

export const FETCH_PRODUCT = gql`
  query FetchProduct($productId: ID!) {
    node(id: $productId) {
      ...ProductFragment
    }
  }
  ${ProductFragment}
`

export const FETCH_SERVICES = gql`
  query {
    collections(first: 3) {
      edges {
        node {
          descriptionHtml
          handle
          id
          image {
            url(transform: {
              maxHeight: 128,
              maxWidth: 128,
              scale: 3
            })
          }
          products(first: 10) {
            edges {
              node {
                descriptionHtml
                id
                images(first: 10) {
                  edges {
                    node {
                      url(transform: {
                        maxWidth: 560,
                        maxHeight: 560,
                        scale: 3
                      })
                    }
                  }
                }
                priceRange {
                  minVariantPrice {
                    amount
                  }
                }
                tags
                title
                variants(first: 10) {
                  edges {
                    node {
                      id
                      image {
                        url(transform: {
                          maxWidth: 560,
                          maxHeight: 560,
                          scale: 3
                        })
                      }
                      price {
                        amount
                      }
                      sku
                      title              
                    }
                  }
                }
                vendor
              }
            }
          }
          title
        }
      }
    }
    products(query: "tag:restring tag:service", first: 1) {
      edges {
        node {
          variants(first: 3) {
            edges {
              node {
                id
                price {
                  amount
                }
                sku
                title              
              }
            }
          }
        }
      }
    }
  }
`

const CheckoutFragment = gql`
  fragment CheckoutFragment on Checkout {
    id
    completedAt
    lineItems(first: 100) {
      edges {
        node {
          customAttributes {
            key
            value
          }
          id
          quantity
          title
          variant {
            compareAtPrice {
              amount
            }
            id
            image {
              url(transform: {
                maxHeight: 128,
                maxWidth: 128,
                scale: 3
              })
            }
            price {
              amount
            }
            product {
              handle
              tags
            }
            sku
            title
          }
        }
      }
    }
    note
    subtotalPrice {
      amount
    }
    totalPrice {
      amount
    }
    totalTax {
      amount
    }
    webUrl
  }
`

const FETCH_CHECKOUT = gql`
  query FetchCheckout($checkoutId: ID!) {
    node(id: $checkoutId) {
      ...CheckoutFragment
    }
  }
  ${CheckoutFragment}
`

export const CREATE_CHECKOUT = gql`
  mutation checkoutCreate ($input: CheckoutCreateInput!){
    checkoutCreate(input: $input) {
      checkout {
        ...CheckoutFragment
      }
      userErrors {
        message
        field
      }
    }
  }
  ${CheckoutFragment}
`

export const CHECKOUT_LINE_ITEMS_ADD = gql`
  mutation checkoutLineItemsAdd($checkoutId: ID!, $lineItems: [CheckoutLineItemInput!]!) {
    checkoutLineItemsAdd(checkoutId: $checkoutId, lineItems: $lineItems) {
      checkout {
        ...CheckoutFragment
      }
      userErrors {
        message
        field
      }
    }
  }
  ${CheckoutFragment}
`

export const CHECKOUT_LINE_ITEMS_UPDATE = gql`
  mutation checkoutLineItemsUpdate($checkoutId: ID!, $lineItems: [CheckoutLineItemUpdateInput!]!) {
    checkoutLineItemsUpdate(checkoutId: $checkoutId, lineItems: $lineItems) {
      checkout {
        ...CheckoutFragment
      }
      userErrors {
        message
        field
      }
    }
  }
  ${CheckoutFragment}
`

export const CHECKOUT_LINE_ITEMS_REMOVE = gql`
  mutation checkoutLineItemsRemove($checkoutId: ID!, $lineItemIds: [ID!]!) {
    checkoutLineItemsRemove(checkoutId: $checkoutId, lineItemIds: $lineItemIds) {
      checkout {
        ...CheckoutFragment
      }
      userErrors {
        field
        message
      }
    }
  }
  ${CheckoutFragment}
`

export const CHECKOUT_ATTRIBUTES_UPDATE = gql`
  mutation checkoutAttributesUpdateV2($checkoutId: ID!, $input: CheckoutAttributesUpdateV2Input!) {
    checkoutAttributesUpdateV2(checkoutId: $checkoutId, input: $input) {
      checkout {
        ...CheckoutFragment
      }
      checkoutUserErrors {
        field
        message
      }
    }
  }
  ${CheckoutFragment}
`
