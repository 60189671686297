import {useMemo} from 'react'
import {joinWords} from './util.js'

export const anyExist = (tags, searchTags) => {
  if (!tags || !searchTags) return false
  for (let i = 0; i < searchTags.length; i++) {
    const term = searchTags[i]
    if (tags.includes(term)) return true
  }
  return false
}

const linearGradient = (colors) => `linear-gradient(to right, ${colors.map((color, index) => {
  const startPercent = index / colors.length * 100
  const endPercent = (index + 1) / colors.length * 100
  return `${color} ${startPercent}% ${endPercent}%`
}).join(', ')})`

export const computeColor = (name) => {
  const formatted = name.toLowerCase().replace(/\s+/g, '')
  switch (formatted) {
    case 'blackstormstriker': return linearGradient(['black', 'white', 'black'])
    case 'blackstriker': return linearGradient(['white', 'black', 'white', 'black', 'white'])
    case 'carolina': return 'lightskyblue'
    case 'carolinastormstriker': return linearGradient(['lightskyblue', 'white', 'lightskyblue'])
    case 'charcoal': return 'dimgray'
    case 'clear': return 'whitesmoke'
    case 'ecdblue': return 'dodgerblue'
    case 'forest': return 'forestgreen'
    case 'frost': return 'powderblue'
    case 'gold': return 'darkkhaki'
    case 'graphene': return 'gainsboro'
    case 'graystormstriker': return linearGradient(['gray', 'white', 'gray'])
    case 'greenstormstriker': return linearGradient(['green', 'white', 'green'])
    case 'hyperliteblue': return 'deepskyblue'
    case 'jungle': return 'olive'
    case 'midnight': return '#333'
    case 'natural': return 'wheat'
    case 'navystormstriker': return linearGradient(['navy', 'white', 'navy'])
    case 'orangestormstriker': return linearGradient(['orange', 'white', 'orange'])
    case 'pacific': return 'darkslateblue'
    case 'pink': return 'deeppink'
    case 'purplestormstriker': return linearGradient(['purple', 'white', 'purple'])
    case 'redstormstriker': return linearGradient(['red', 'white', 'red'])
    case 'royal': return 'royalblue'
    case 'royalstormstriker': return linearGradient(['royalblue', 'white', 'royalblue'])
    case 'ruby': return 'crimson'
    case 'titanium': return '#555'
    case 'volt': return 'greenyellow'
    case 'whitestormstriker': return linearGradient(['white', 'black', 'white'])
    case 'whitestriker': return linearGradient(['black', 'white', 'black'])
    case 'yellowstormstriker': return linearGradient(['yellow', 'white', 'yellow'])
    default: return formatted
  }
}

export const computeHighlight = (isSoldOut, price, comparePrice, tags) => {
  if (isSoldOut) return {icon: 'slash', text: 'Sold out', color: '#22222288'}
  if (tags.includes('indicator-top-pick')) return {icon: 'award', text: 'Top pick', color: '#0f52ba'}
  if (tags.includes('indicator-rare-find')) return {icon: 'gem', text: 'Rare find', color: '#ff7417'}
  const priceNum = parseFloat(price) || 0
  const comparePriceNum = parseFloat(comparePrice) || 0
  if (comparePriceNum > 0 && (priceNum / comparePriceNum) < 0.8)
    return {icon: 'tag', text: 'Low price', color: '#00a86b'}
  return null
}

export const computeIntegerId = (rawId) => !rawId || !rawId.includes('/') ? null : rawId.split('/').pop()

export const computeSubtitle = (tags) => {
  const categoryTag = findTag(tags, 'category')
  switch (categoryTag) {
    case 'category-apparel':
      const genderTag = findTag(tags, 'gender')
      const typeTag = findTag(tags, 'type')
      return joinWords([
        mapTag(genderTag, {
          'men': `Men's`,
          'women': `Women's`,
          'unisex': 'Unisex',
        }),
        mapTag(typeTag, {
          'beanie': 'Beanie',
          'hat': 'Hat',
          'hoodie': 'Hoodie',
          'pullover': 'Pullover',
          'tee': 'Tee',
        })
      ])
    case 'category-head':
      const brandTag = findTag(tags, 'brand')
      const conditionTag = findTag(tags, 'condition')
      const featureTag = findTag(tags, 'feature')
      const sportTag = findTag(tags, 'sport')
      return joinWords([
        mapTag(conditionTag, {
          'new': 'New',
          'used': 'Used',
        }),
        mapTag(featureTag, {
          'dyed': 'Dyed',
          'strung': 'Strung',
          'unstrung': 'Unstrung',
        }),
        mapTag(sportTag, {
          'box': 'Box',
          'men': `Men's`,
          'women': `Women's`,
        }),
        mapTag(brandTag, {
          'brine': 'Brine',
          'ecd': 'ECD',
          'epoch': 'Epoch',
          'gait': 'Gait',
          'maverik': 'Maverik',
          'nike': 'Nike',
          'powell': 'Powell',
          'stx': 'STX',
          'string_king': 'StringKing',
          'under_armour': 'Under Armour',
          'warrior': 'Warrior',
        }),
        'Head',
      ])
    default:
      const category = mapTag(categoryTag, {
        'dye': 'Dye',
        'pocket': 'Pocket',
      })
      const filters = tags.filter(tag => ['men', 'women', 'goalie'].includes(tag)).map(tag => {
        return {
          'men': "Men's",
          'women': "Women's",
          'goalie': "Goalie",
        }[tag]
      })
      return joinWords([
        !category || filters.length !== 1 ? null : filters[0],
        category,
      ])
  }
}

export const computeEarliestDelivery = (tags = [], theoristCount) => {
  if (tags.includes('design')) return 4 * (theoristCount || 0) + 3
  if (tags.includes('policy-fulfillment')) return 3
  if (tags.includes('category-head') && !tags.includes('policy-on_demand')) return 5
  return 7
}

export const filterTags = (tags, tagKey) => tags.filter(tag => {
  const tokens = tag.split('-')
  return tokens.length > 1 && tokens[0] === tagKey
})

export const findTag = (tags, tagKey) => tags.find(tag => {
  const tokens = tag.split('-')
  return tokens.length > 1 && tokens[0] === tagKey
})

export const findTagValue = (tags, tagKey) => {
  const tag = findTag(tags, tagKey)
  if (!tag) return null
  return tag.split('-')[1]
}

export const isBlackFriday = () => false // (tags) => anyExist(tags, ['special-black_friday'])

export const isDesignHead = (tags) => anyExist(tags, ['custom-head'])

export const isDesignOnly = (tags) => anyExist(tags, ['custom-dye', 'custom-pocket'])

export const isFulfilled = (tags) => anyExist(tags, ['policy-fulfillment'])

export const isOnDemand = (tags) => anyExist(tags, ['category-apparel', 'policy-on_demand'])

export const isPending = (tags) => anyExist(tags, ['pending', 'status-pending'])

export const isPortrait = (tags) => anyExist(tags, [
  'category-dye',
  'category-handle',
  'category-head',
  'category-pocket',
  'category-stick',
])

export const isReturnEligible = (tags) =>
  anyExist(tags, ['category-apparel', 'policy-returns', 'policy-fulfillment', 'policy-on_demand'])

export const mapTag = (tag, tagMap) => !tag || !tagMap ? null
  : (tag.includes('-') ? tagMap[tag.split('-')[1]] : tagMap[tag])

export const useProductOptions = (product) => useMemo(() => {
  if (!product) return []
  const isAvailable = (name, value, variant) => variant.availableForSale
    && variant.selectedOptions.findIndex(o => o.name === name && o.value === value) >= 0
  return product.options.map(option => {
    return {
      ...option,
      availability: option.values.map(value => product.variants
        .some(variant => isAvailable(option.name, value, variant))),
    }
  })
}, [product])

export const useSelectedOptions = (options, variants, selectedVariant, onSelectVariant) => {
  const selectedOptions = selectedVariant.selectedOptions.map(option => {
    return {[option.name]: option.value}
  }).reduce((options, option) => {
    return {...options, ...option}
  }, {})
  return {
    selectedOptions: selectedOptions,
    onSelectOption: (option, index) => {
      const value = option.values[index]
      const title = options
      .map(o => {
        if (o.name === option.name) return value
        return selectedOptions[o.name]
      })
      .reduce((title, option) => {
        if (!title) return option
        return `${title} / ${option}`
      })
      const variant = variants.find(variant => variant.title === title)
      if (!variant) {
        const containingLastSelection = (o) => o.name === option.name && o.value === value
        const availableVariant =
          variants.find(variant => variant.selectedOptions.some(option => containingLastSelection(option)))
        if (availableVariant) onSelectVariant(availableVariant)
      } else {
        onSelectVariant(variant)
      }
    },
  }
}
