import React, {useState} from 'react'
import moment from 'moment'
import {useHistory, useRouteMatch} from 'react-router-dom'
import AppImage from '../../../../../../components/AppImage'
import Button from '../../../../../../components/Button'
import LoadingIndicator from '../../../../../../components/LoadingIndicator'
import PaginationButton from '../../../../../../components/PaginationButton'
import Tab, {TabGroup} from '../../../../../../components/Tab'
import Text from '../../../../../../components/Text'
import {orderBy, useQueryWithPagination, where} from '../../../../../../services/firebase'
import {isFulfilled, isPending} from '../../../../../../services/product'
import {classList} from '../../../../../../services/util'
import ProductInventory from '../../components/ProductInventory'
import ProductStatus from '../../components/ProductStatus'
import './styles.scss'

export default function DashProductList({theorist}) {
  const isAdmin = theorist.role === 'admin'
  const history = useHistory()
  const {url} = useRouteMatch()
  const [filter, setFilter] = useState(!isAdmin ? 'listed-by-you' : 'all')
  const [status, setStatus] = useState('ACTIVE')
  const {loading, error, docs, hasMore, fetchMore} = useQueryWithPagination(
    'products',
    [
      filter === 'all' ? null : where('shopify.tags', 'array-contains', theorist.handle),
      where('shopify.status', '==', status),
      orderBy(
        filter === 'all' ? 'shopify.createdAt' : 'shopify.title',
        filter === 'all' ? 'desc' : 'asc',
      ),
    ],
    [filter, status],
  )
  if (error) console.log(error)
  return <div className="DashProductList">
    <div className="DashProductList__head">
      <Text styleVariant="heading2">Products</Text>
      <Button slim onClick={() => history.push(`${url}/new`)}>Add product</Button>
    </div>
    {!isAdmin ? null : <TabGroup
      className="DashProductList__tabs"
      styleVariant="pill">
      {['all', 'listed-by-you'].map(tab => <Tab
        key={`filterTab--${tab}`}
        onClick={() => setFilter(tab)}
        selected={tab === filter}>
        {(tab.charAt(0).toUpperCase() + tab.slice(1)).replace(/-/g, ' ')}
      </Tab>)}
    </TabGroup>}
    <TabGroup className="DashProductList__tabs">
      {['Active', 'Draft', 'Archived'].map(title => <Tab
        key={title}
        selected={status === title.toUpperCase()}
        onClick={() => setStatus(title.toUpperCase())}>
        {title}
      </Tab>)}
    </TabGroup>
    {loading && (docs || []).length < 1 ? <LoadingIndicator /> : ((docs || []).length < 1 ? <Text>
      You have no {status.toLowerCase()} products listed.
    </Text> : <table className="DashProductList__table">
      <thead>
      <tr>
        <th className="DashProductList__th" />
        <th className="DashProductList__th">Product</th>
        <th className="DashProductList__th">Status</th>
        <th className="DashProductList__th">Inventory</th>
        {!isAdmin ? null : <th className="DashProductList__th">Vendor</th>}
      </tr>
      </thead>
      <tbody>
      {(docs || [])
      .map(doc => {
        const shopify = doc.data.shopify || {}
        return {
          key: doc.id,
          ...shopify,
          inventoryProps: {totalInventory: shopify.totalInventory, tracksInventory: shopify.tracksInventory},
          statusProps: {
            pending: isPending(shopify.tags),
            status: shopify.status,
            fulfilled: isFulfilled(shopify.tags),
          },
        }
      })
      .map(product => <tr
        className={classList([
          'DashProductList__product',
          !product.statusProps.fulfilled || isAdmin ? null : 'DashProductList__product--disabled',
        ])}
        key={product.key}
        onClick={() => {
          if (product.statusProps.fulfilled && !isAdmin) return
          history.push(`${url}/${product.key}`)
        }}>
        <td className="DashProductList__td">
          <AppImage
            src={(product.images[0] || {}).url}
            width={64}
            height={80}
            radius={12}
            bordered />
        </td>
        <td className="DashProductList__td">
          <div className="DashProductList__productInfo">
            <Text className="DashProductList__link">{product.title}</Text>
            <ProductStatus className="DashProductList__productInfoExtra" {...product.statusProps} />
            <ProductInventory className="DashProductList__productInfoExtra" {...product.inventoryProps} />
            {isAdmin && <Text
              className="DashProductList__productInfoExtra"
              styleVariant="body3">
              Listed by <b>{product.vendor}</b> on <b>
                {moment(product.createdAt).format('MMM D [at] h:mm a')}
              </b>
            </Text>}
          </div>
        </td>
        <td className="DashProductList__td DashProductList__td--extra">
          <ProductStatus {...product.statusProps} />
        </td>
        <td className="DashProductList__td DashProductList__td--extra">
          <ProductInventory {...product.inventoryProps} />
        </td>
        {!isAdmin ? null : <td className="DashProductList__td DashProductList__td--extra">
          <Text styleVariant="body3">{product.vendor}</Text>
        </td>}
      </tr>)}
      </tbody>
    </table>)}
    <PaginationButton
      className="DashProductList__pagination"
      hasPagination={hasMore}
      onPagination={() => fetchMore()} />
  </div>
}
