import React from 'react'
import {ReactComponent as ArrowLeft} from './images/arrow-left.svg'
import {ReactComponent as ArrowRight} from './images/arrow-right.svg'
import {ReactComponent as Award} from './images/award.svg'
import {ReactComponent as AwardFilled} from './images/award-filled.svg'
import {ReactComponent as Bank} from './images/bank.svg'
import {ReactComponent as Box} from './images/box.svg'
import {ReactComponent as Briefcase} from './images/briefcase.svg'
import {ReactComponent as Calendar} from './images/calendar.svg'
import {ReactComponent as Cart} from './images/cart.svg'
import {ReactComponent as Check} from './images/check.svg'
import {ReactComponent as ChevronDown} from './images/chevron-down.svg'
import {ReactComponent as ChevronLeft} from './images/chevron-left.svg'
import {ReactComponent as ChevronRight} from './images/chevron-right.svg'
import {ReactComponent as Clear} from './images/clear.svg'
import {ReactComponent as Contact} from './images/contact.svg'
import {ReactComponent as CreditCard} from './images/credit-card.svg'
import {ReactComponent as Discord} from './images/discord.svg'
import {ReactComponent as Edit} from './images/edit.svg'
import {ReactComponent as Facebook} from './images/facebook.svg'
import {ReactComponent as Gear} from './images/gear.svg'
import {ReactComponent as Gem} from './images/gem.svg'
import {ReactComponent as Gift} from './images/gift.svg'
import {ReactComponent as GroupMe} from './images/group-me.svg'
import {ReactComponent as Head} from './images/head.svg'
import {ReactComponent as Heart} from './images/heart.svg'
import {ReactComponent as Home} from './images/home.svg'
import {ReactComponent as Inbox} from './images/inbox.svg'
import {ReactComponent as Info} from './images/info.svg'
import {ReactComponent as Instagram} from './images/instagram.svg'
import {ReactComponent as Layout} from './images/layout.svg'
import {ReactComponent as LifeBuoy} from './images/life-buoy.svg'
import {ReactComponent as Lightning} from './images/lightning.svg'
import {ReactComponent as Location} from './images/location.svg'
import {ReactComponent as Lock} from './images/lock.svg'
import {ReactComponent as Mail} from './images/mail.svg'
import {ReactComponent as Menu} from './images/menu.svg'
import {ReactComponent as Minus} from './images/minus.svg'
import {ReactComponent as Package} from './images/package.svg'
import {ReactComponent as Paperclip} from './images/paperclip.svg'
import {ReactComponent as Plus} from './images/plus.svg'
import {ReactComponent as Search} from './images/search.svg'
import {ReactComponent as Share} from './images/share.svg'
import {ReactComponent as Shirt} from './images/shirt.svg'
import {ReactComponent as ShoppingBag} from './images/shopping-bag.svg'
import {ReactComponent as Slash} from './images/slash.svg'
import {ReactComponent as Sliders} from './images/sliders.svg'
import {ReactComponent as Star} from './images/star.svg'
import {ReactComponent as StringKit} from './images/string-kit.svg'
import {ReactComponent as StringTheory} from './images/string-theory.svg'
import {ReactComponent as Tag} from './images/tag.svg'
import {ReactComponent as TikTok} from './images/tik-tok.svg'
import {ReactComponent as Trash} from './images/trash.svg'
import {ReactComponent as Truck} from './images/truck.svg'
import {ReactComponent as User} from './images/user.svg'
import {ReactComponent as Users} from './images/users.svg'
import './styles.scss'

export default function AppIcon({className, icon, ...props}) {
  const SvgIcon = map(icon)
  if (!SvgIcon) return null
  return <SvgIcon
    className={`AppIcon ${className}`}
    {...props} />
}

function map(icon) {
  switch (icon) {
    case 'arrow-right': return ArrowRight
    case 'arrow-left': return ArrowLeft
    case 'award': return Award
    case 'award-filled': return AwardFilled
    case 'bank': return Bank
    case 'box': return Box
    case 'briefcase': return Briefcase
    case 'calendar': return Calendar
    case 'cart': return Cart
    case 'check': return Check
    case 'chevron-down': return ChevronDown
    case 'chevron-left': return ChevronLeft
    case 'chevron-right': return ChevronRight
    case 'clear': return Clear
    case 'contact': return Contact
    case 'credit-card': return CreditCard
    case 'discord': return Discord
    case 'edit': return Edit
    case 'facebook': return Facebook
    case 'gear': return Gear
    case 'gem': return Gem
    case 'gift': return Gift
    case 'group-me': return GroupMe
    case 'head': return Head
    case 'heart': return Heart
    case 'home': return Home
    case 'inbox': return Inbox
    case 'info': return Info
    case 'instagram': return Instagram
    case 'layout': return Layout
    case 'life-buoy': return LifeBuoy
    case 'lightning': return Lightning
    case 'location': return Location
    case 'lock': return Lock
    case 'mail': return Mail
    case 'menu': return Menu
    case 'minus': return Minus
    case 'package': return Package
    case 'paperclip': return Paperclip
    case 'plus': return Plus
    case 'search': return Search
    case 'share': return Share
    case 'shirt': return Shirt
    case 'shopping-bag': return ShoppingBag
    case 'slash': return Slash
    case 'sliders': return Sliders
    case 'star': return Star
    case 'string-kit': return StringKit
    case 'string-theory': return StringTheory
    case 'tag': return Tag
    case 'tik-tok': return TikTok
    case 'trash': return Trash
    case 'truck': return Truck
    case 'user': return User
    case 'users': return Users
    default: return null
  }
}
