import React, {useEffect, useState} from 'react'
import parse from 'html-react-parser'
import {useMediaQuery} from 'react-responsive/src'
import {useHistory, useLocation, useParams} from 'react-router'
import * as Scroll from 'react-scroll'
import {useSessionStorage} from 'usehooks-ts'
import ImageSelector from '../../components/ImageSelector'
import LoadingIndicator from '../../components/LoadingIndicator'
import ProductConfigurator from '../../components/ProductConfigurator'
import ProductDelivery from '../../components/ProductDelivery'
import ProductOptions from '../../components/ProductOptions'
import ReviewList from '../../components/ReviewList'
import RichText from '../../components/RichText'
import Text from '../../components/Text'
import TheoristSelector from '../../components/TheoristSelector'
import {useProductTheorist} from '../../services/contentful'
import {useQuery, where} from '../../services/firebase'
import {
  computeIntegerId,
  findTagValue,
  isDesignHead,
  isDesignOnly,
  isPortrait,
  useProductOptions
} from '../../services/product'
import {flattenData, resizeImage} from '../../services/shopify'
import {decodeParams, encodeParams, useHashScroll} from '../../services/url'
import NotFound from '../NotFound'
import Shell from '../Shell'
import ProductFooter from './scenes/ProductFooter'
import ProductInfo from './scenes/ProductInfo'
import ProductOrder from './scenes/ProductOrder'
import './styles.scss'

export default function Product() {
  const isMobile = useMediaQuery({maxWidth: 720})
  const history = useHistory()
  const {handle} = useParams()
  const {hash, pathname, search} = useLocation()
  const {d: isDesign, t: theoristName, v: variantId} = decodeParams(search)
  const {loading, error, docs} = useQuery('products', [
    where('handle', '==', handle),
  ], [handle])
  const [product, setProduct] = useState(null)
  const [images, setImages] = useState({
    gallery: [],
    selectedIndex: 0,
  })
  const [selectedVariant, setSelectedVariant] = useState(null)
  const [selectedConfig, setSelectedConfig] = useState({theorist: theoristName})
  const [customKey, setCustomKey] = useState(null)
  const [customOrder] = useSessionStorage('custom', {})
  useEffect(() => {
    if (!docs || docs.length < 1) return
    const shopifyData = docs[0].data.shopify
    setProduct({
      ...docs[0].data,
      ...flattenData(shopifyData),
    })
  }, [docs])
  useEffect(() => {
    if (!product) return
    const customKey = findTagValue(product.tags, 'custom')
    setCustomKey(customKey)
    if (customKey && customOrder[customKey]) setSelectedConfig({
      ...customOrder[customKey].config,
      ...(!theoristName ? {} : {theorist: theoristName}),
    })
    const searchVariant = product.variants.find(variant => computeIntegerId(variant.id) === variantId)
    const availableVariant = product.variants.find(variant => variant.availableForSale === true)
    setSelectedVariant(searchVariant || availableVariant || product.variants[0])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product])
  const theorist = useProductTheorist(product)
  const refreshGallery = (additionalImages) => product.images.filter(image => {
    if (image.id === (selectedVariant.image || {}).id) return true
    return !product.variants.map(variant => (variant.image || {}).id).includes(image.id)
  }).concat(additionalImages || [])
  useEffect(() => {
    if (!selectedVariant) return
    history.replace(`${pathname}${encodeParams({
      d: !isDesign ? 0 : 1,
      t: selectedConfig?.theorist,
      v: computeIntegerId(selectedVariant.id),
    })}${hash}`)
    const gallery = refreshGallery()
    const imageIndex = gallery.findIndex(image => image.id === (selectedVariant.image || {}).id)
    setImages(prev => ({
      gallery: gallery,
      selectedIndex: imageIndex >= 0 ? imageIndex : prev.selectedIndex,
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedConfig?.theorist, selectedVariant])
  useEffect(() => {
    if (!selectedConfig || !selectedConfig.images) return
    const gallery = refreshGallery(selectedConfig.imageOrder
      .filter(tag => !!selectedConfig.images[tag])
      .map(tag => selectedConfig.images[tag]))
    const lastClicked = selectedConfig.lastClicked
    const imageIndex = !lastClicked ? -1
      : gallery.findIndex(image => image.id === selectedConfig.images[lastClicked].id)
    setImages(prev => ({
      gallery: gallery,
      selectedIndex: imageIndex >= 0 ? imageIndex : prev.selectedIndex,
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedConfig])
  useHashScroll(hash, [product, selectedVariant])
  const options = useProductOptions(product)
  if (loading) return <Shell>
    <LoadingIndicator />
  </Shell>
  if (error) console.log(`Failed to fetch product: ${error}`)
  if (!product) return <NotFound />
  if (!selectedVariant) return <Shell
    className="Product"
    title={product.title}
    noSticky />
  const productInfo = <ProductInfo
    className="Product__info"
    title={product.title}
    tags={product.tags}
    theorist={theorist}
    vendor={product.vendor}
    price={selectedVariant.price}
    comparePrice={selectedVariant.compareAtPrice}
    orderCount={product.orderCount}
    reviewCount={product.reviewCount}
    reviewTotal={product.reviewTotal} />
  const configuratorTag = findTagValue(product.tags, 'configurator')
  return <Shell
    className="Product"
    title={product.title}
    noSticky showChat>
    <div className="Product__content">
      {!isMobile ? null : productInfo}
      <div className="Product__images">
        <ImageSelector
          className="Product__imageSelector"
          styleVariant={isPortrait(product.tags) ? null : 'square'}
          images={images.gallery.map(image => resizeImage(image.url, 1080))}
          featuredIndex={images.selectedIndex}
          onClickImage={(index) => setImages(prev => ({
            gallery: prev.gallery,
            selectedIndex: index,
          }))}
          onEnterImage={(index) => setImages(prev => ({
            gallery: prev.gallery,
            selectedIndex: index,
          }))} />
      </div>
      <div>
        {!isMobile ? productInfo : null}
        {product.totalVariants > 1 ? <ProductOptions
          className="Product__options"
          options={options}
          variants={product.variants}
          selected={selectedVariant}
          onSelect={(variant) => setSelectedVariant(variant)} /> : null}
        {!configuratorTag ? null : <ProductConfigurator
          className="Product__configurator"
          tag={configuratorTag}
          config={selectedConfig}
          onChange={(config) => setSelectedConfig(prev => ({
            ...prev,
            ...config,
          }))} />}
        {isDesignOnly(product.tags) && !theorist && <div className="Product__theorist">
          <Text styleVariant="body2">Theorist</Text>
          <TheoristSelector
            initialTheorist={selectedConfig?.theorist}
            onSelect={(theoristName) => setSelectedConfig(prev => ({
              ...prev,
              theorist: theoristName,
            }))} />
        </div>}
        {customKey ? null : <ProductDelivery
          className="Product__shippingInfo"
          tags={product.tags} />}
        <ProductOrder
          className="Product__order"
          productId={product.id}
          variantId={selectedVariant.id}
          availableForSale={product.status === 'ACTIVE' && selectedVariant.availableForSale}
          customKey={customKey}
          designOnly={isDesign || isDesignOnly(product.tags)}
          selectedConfig={selectedConfig}
          inventoryQuantity={selectedVariant.inventoryQuantity} />
        {!product.descriptionHtml || !product.description ? null : <RichText className="Product__description">
          {parse(product.descriptionHtml || product.description)}
        </RichText>}
        {customKey ? isDesignHead(product.tags) && <Text>
          This product is not covered by the manufacturer's warranty; however, we offer a comprehensive warranty
          matching service to ensure your peace of mind. Should you encounter any issues with your purchase, we will
          match the manufacturer's warranty terms and conditions to provide you with full support. Please note that
          while dyed heads are also covered, the dye itself is not included in the warranty.
        </Text> : <ProductFooter
          className="Product__footer"
          theorist={theorist}
          vendor={product.vendor}
          tags={product.tags} />}
        <Scroll.Element name="reviews">
          <div className="Product__reviews">
            <ReviewList
              columns={{
                md: 1,
                lg: 2,
              }}
              reviewCount={product.reviewCount}
              reviewTotal={product.reviewTotal}
              productId={computeIntegerId(product.id)}
              theoristHandle={theorist?.theoristHandle}
              editorProps={{
                image: resizeImage(images.gallery[0]?.url, 480),
                product: product.title,
                vendor: product.vendor,
              }}/>
          </div>
        </Scroll.Element>
      </div>
    </div>
  </Shell>
}
